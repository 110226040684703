import './pricing.scss';
import React from 'react';
import { HLocation } from '@reach/router';
import Footer from '@components/Footer';
import Header from '@components/Header';
import IndexLayout from '@layouts/index';
import PageHeader from '@page-components/pricing/PageHeader';
import PricingCalculator from '@page-components/pricing/PricingCalculator';
import Thumbnail from '@page-components/pricing/assets/thumbnail.jpg';
import PricingPlans from '@page-components/pricing/PricingPlans';
import ConsumptionOverview from '@page-components/pricing/ConsumptionOverview';

const PricingPage = ({ location }: { location: HLocation }) => {
  const { hash } = location;

  return (
    <IndexLayout
      pageName="pricing"
      helmet={{
        title: 'TileDB Cloud Pricing',
        description: 'Explore TileDB pricing options.',
        shareImage: {
          url: Thumbnail,
          width: 2000,
          height: 1050,
        },
      }}
    >
      <Header variant="transparent-white" />
      <main className="main">
        <PageHeader />
        <PricingPlans initialActiveTab={hash === '#individual-plans' ? 'individual' : 'organizations'} />
        <ConsumptionOverview />
        <PricingCalculator />
      </main>
      <Footer variant="light" />
    </IndexLayout>
  );
};

export default PricingPage;
