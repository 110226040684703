import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ConsumptionTypes } from '@constants/ConsumptionTypes';
import { PricingDetailsType } from '@custom-types/PricingDetailsType';
import InputSelect from '@components/InputSelect';
import { useViewport } from '@hooks/useViewport';
import capitalizeFirstLetter from '@utils/helpers/capitalizeFirstLetter';
import pluralize from '@utils/helpers/pluralize';
import ConsumptionTypeTitle from '../../../components/ConsumptionTypeTitle';
import CalculatorSection from '../CalculatorSection';
import CalculatorHeader from '../CalculatorSection/CalculatorHeader';
import CostBlock from '../CostBlock';
import OptionWithScaleIcon from '../OptionWithScaleIcon';

const sizeOptions = [
  {
    label: 'Small server',
    value: 'small-server',
  },
  {
    label: 'Large server',
    value: 'large-server',
  },
];

const scaleOptions = [
  {
    label: <OptionWithScaleIcon>Scale: Minutes</OptionWithScaleIcon>,
    value: 'minutes',
  },
  {
    label: <OptionWithScaleIcon>Scale: Hours</OptionWithScaleIcon>,
    value: 'hours',
  },
];

const minutesMarkLabels: any = {
  0: '0m',
  10: '10m',
  20: '20m',
  30: '30m',
  40: '40m',
  50: '50m',
  60: '1hr',
  70: '70m',
  80: '80m',
  90: '90m',
  100: '100m',
  110: '110m',
  120: '2hrs',
};

const hoursMarkLabels: any = {
  0: '0hrs',
  10: '1hr',
  20: '2hrs',
  30: '3hrs',
  40: '4hrs',
  50: '5hrs',
  60: '6hrs',
  70: '7hrs',
  80: '8hrs',
  90: '9hrs',
  100: '10hrs',
  110: '11hrs',
  120: '12hrs',
};
const hoursMarkLabelsMobile: any = {
  0: '0h',
  10: '1h',
  20: '2h',
  30: '3h',
  40: '4h',
  50: '5h',
  60: '6h',
  70: '7h',
  80: '8h',
  90: '9h',
  100: '10h',
  110: '11h',
  120: '12h',
};

const costs: any = {
  'small-server': {
    minutes: 0.002,
    hours: 0.12,
  },
  'large-server': {
    minutes: 0.016,
    hours: 0.96,
  },
};

const scaleDescriptions: any = {
  minutes: 'minute',
  hours: 'hour',
};
const scaleShortDescriptions: any = {
  minutes: 'min',
  hours: 'hour',
};

const highlightMarks = [60, 120];

interface JupyterNotebooksCalculatorProps {
  onChange: (data: PricingDetailsType) => void;
}

const JupyterNotebooksCalculator: React.FC<JupyterNotebooksCalculatorProps> = (props) => {
  const { onChange } = props;
  const [minutesValue, setMinutesValue] = useState(10);
  const [hoursValue, setHoursValue] = useState(10);
  const [sizeValue, setSizeValue] = useState(sizeOptions[0]);
  const [scaleValue, setScaleValue] = useState(scaleOptions[0]);
  const [cost, setCost] = useState({ value: 0, cost: 0, scale: '', costScale: '' });
  const { width } = useViewport();

  const scale = useMemo(() => {
    return scaleValue.value;
  }, [scaleValue]);

  const value = useMemo(() => {
    let v = 0;
    if (scaleValue.value === 'minutes') {
      v = minutesValue;
    } else if (scaleValue.value === 'hours') {
      v = hoursValue;
    }
    return v;
  }, [scaleValue, minutesValue, hoursValue]);

  const displayValue = useMemo(() => {
    let v = 0;
    if (scaleValue.value === 'minutes') {
      v = minutesValue;
    } else if (scaleValue.value === 'hours') {
      v = hoursValue / 10;
    }
    return v;
  }, [scaleValue, minutesValue, hoursValue]);

  const markLabels = useMemo(() => {
    if (scaleValue.value === 'minutes') {
      return minutesMarkLabels;
    }
    if (scaleValue.value === 'hours') {
      if (width <= 500) {
        return hoursMarkLabelsMobile;
      }
      return hoursMarkLabels;
    }
    return [];
  }, [scaleValue.value, width]);

  useEffect(() => {
    const c = costs[sizeValue.value][scaleValue.value];

    let costScale = '';
    if (scaleValue.value === 'minutes') {
      costScale = 'per minute';
    } else if (scaleValue.value === 'hours') {
      costScale = 'per hour';
    }

    const scaleDescr = pluralize(displayValue, scaleDescriptions[scaleValue.value], `${scaleDescriptions[scaleValue.value]}s`);

    setCost({ value: displayValue, cost: c, scale: scaleDescr, costScale });
  }, [value, sizeValue, scaleValue]);

  useEffect(() => {
    onChange(cost);
  }, [cost]);

  const handleSliderChange = useCallback(
    (v: number) => {
      if (scaleValue.value === 'hours') {
        setHoursValue(v);
      }
      if (scaleValue.value === 'minutes') {
        setMinutesValue(v);
      }
    },
    [scaleValue]
  );

  return (
    <div className="TDB-CalculatorSection__Wrapper">
      <ConsumptionTypeTitle className="TDB-CalculatorSection__Header" type={ConsumptionTypes.Notebooks} />
      <CalculatorSection
        type={ConsumptionTypes.Notebooks}
        header={
          <CalculatorHeader title="Jupyter Notebooks" type={ConsumptionTypes.Notebooks}>
            <InputSelect options={sizeOptions} value={sizeValue} onChange={(newValue: any) => setSizeValue(newValue)} />
            <InputSelect options={scaleOptions} value={scaleValue} onChange={(newValue: any) => setScaleValue(newValue)} />
          </CalculatorHeader>
        }
        description={`${capitalizeFirstLetter(scaleValue.value)} of a Notebook being active.`}
        onSliderChange={handleSliderChange}
        displayValue={{
          value: displayValue,
          description: pluralize(displayValue, scaleShortDescriptions[scale], `${scaleShortDescriptions[scale]}s`),
        }}
        sliderValue={value}
        markLabels={markLabels}
        highlightMarks={highlightMarks}
        min={0}
        max={120}
      />
      <CostBlock cost={cost} type={ConsumptionTypes.Notebooks} costDescription="Running time" mobileOnly />
    </div>
  );
};

export default JupyterNotebooksCalculator;
