import * as React from 'react';
import { AnimatePresence } from 'framer-motion';
import Container from '@components/Container';
import Tab from '@components/Tab';
import IndividualsPricingPlan from './IndividualsPricingPlan';
import OrganizationsPricingPlan from './OrganizationsPricingPlan';
import './PricingPlans.scss';

type TabTypes = 'individual' | 'organizations';

interface PricingPlansProps {
  initialActiveTab: TabTypes;
}

const PricingPlans: React.FC<PricingPlansProps> = ({ initialActiveTab }) => {
  const [activeTab, setActiveTab] = React.useState<TabTypes>(initialActiveTab);
  return (
    <div className="TDB-PricingPlans">
      <Container maxWidth={1136}>
        <div className="TDB-PricingPlans__tabs">
          <Tab id="individuals-plans" onClick={() => setActiveTab('individual')} active={activeTab === 'individual'}>
            Individuals
          </Tab>
          <Tab id="organization-plans" onClick={() => setActiveTab('organizations')} active={activeTab === 'organizations'}>
            Organizations
          </Tab>
        </div>
        <AnimatePresence initial>
          {activeTab === 'individual' && <IndividualsPricingPlan />}
          {activeTab === 'organizations' && <OrganizationsPricingPlan />}
        </AnimatePresence>
      </Container>
    </div>
  );
};

export default PricingPlans;
