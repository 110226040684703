import classNames from 'classnames';
import { motion } from 'framer-motion';
import * as React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Button from '@components/Button';
import Tooltip from '@components/Tooltip';
import links from '@constants/links';
import Typography from '@components/Typography';
import './Plan.scss';

interface Feature {
  active: boolean;
  text: string;
  tooltip?: React.ReactNode;
  key: string;
}

type Props = {
  title: string;
  description: string;
  pricing: string;
  pricingPrefix?: string;
  credits: string;
  seats?: string;
  seatSubtitle?: string;
  renderSignupButton: boolean;
  gift: string;
  features: Feature[];
};

const Plan: React.FC<Props> = (props) => {
  const { title, description, pricing, credits, renderSignupButton, gift, features, seats, pricingPrefix, seatSubtitle } = props;
  return (
    <motion.div className="TDB-Plan">
      <Typography as="h5" fontSize="heading-4" fontWeight="semi-bold" color="neutral-600" className="TDB-Plan__title">
        {title}
      </Typography>
      <Typography className="TDB-Plan__description" as="p" fontSize="body" fontWeight="medium" color="neutral-600">
        {description}
      </Typography>
      <Typography className="TDB-Plan__pricing" as="h4" fontSize="heading-3" fontWeight="bold" color="neutral-900">
        <Typography fontWeight="semi-bold" className="TDB-Plan__pricing-prefix" color="neutral-600">
          {pricingPrefix}
        </Typography>
        {pricing}
      </Typography>
      <Typography className="TDB-Plan__fee-label" as="p" fontSize="body" fontWeight="medium" color="neutral-600">
        subscription fee
      </Typography>
      <div className="TDB-Plan__infobox">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.66461 3.52145L4.78706 4.24151C4.89866 4.31309 5.02306 4.34701 5.14643 4.34701C5.36577 4.34701 5.58108 4.23898 5.70832 4.04032C5.90712 3.73051 5.8171 3.31771 5.50713 3.1192L4.38468 2.39914C4.07442 2.20049 3.66207 2.28977 3.46356 2.60033C3.26475 2.91014 3.35478 3.32294 3.66474 3.52145L3.66461 3.52145Z"
            fill="#A6A6A6"
          />
          <path
            d="M4.64097 9.16864C4.58234 8.8054 4.23576 8.55867 3.87672 8.61658L2.56067 8.82878C2.19713 8.88741 1.94998 9.2298 2.00861 9.59303C2.06128 9.92055 2.34446 10.1536 2.66573 10.1536C2.70115 10.1536 2.73701 10.1511 2.77273 10.1451L4.08877 9.93291C4.45231 9.87427 4.69946 9.53189 4.64084 9.16866L4.64097 9.16864Z"
            fill="#A6A6A6"
          />
          <path
            d="M2.60711 6.75117L3.91245 7.02259C3.95828 7.03167 4.00396 7.03628 4.0489 7.03628C4.35812 7.03628 4.63548 6.82022 4.70081 6.50563C4.77566 6.14492 4.54427 5.79212 4.18387 5.7165L2.87853 5.44508C2.51589 5.37083 2.16502 5.60207 2.09017 5.96202C2.01532 6.32273 2.24671 6.67555 2.60711 6.75117Z"
            fill="#A6A6A6"
          />
          <path
            d="M18.8536 4.347C18.9767 4.347 19.1017 4.31307 19.213 4.24149L20.3354 3.52143C20.6452 3.32278 20.7354 2.91013 20.5366 2.60031C20.3378 2.2905 19.925 2.19929 19.6155 2.39913L18.493 3.11919C18.1832 3.31784 18.093 3.73049 18.2919 4.04031C18.4191 4.23896 18.6343 4.347 18.8537 4.347L18.8536 4.347Z"
            fill="#A6A6A6"
          />
          <path
            d="M21.4395 8.82884L20.1234 8.61664C19.7624 8.55801 19.4173 8.80548 19.3592 9.16871C19.3005 9.53195 19.5475 9.87448 19.9112 9.93296L21.2273 10.1452C21.2631 10.151 21.2988 10.1536 21.3343 10.1536C21.6555 10.1536 21.9387 9.9206 21.9914 9.59308C22.05 9.22984 21.803 8.8873 21.4393 8.82883L21.4395 8.82884Z"
            fill="#A6A6A6"
          />
          <path
            d="M19.2989 6.50584C19.3643 6.82027 19.6418 7.0365 19.9508 7.0365C19.9958 7.0365 20.0413 7.03203 20.0873 7.02281L21.3926 6.75139C21.7529 6.67579 21.9844 6.32298 21.9096 5.96226C21.8344 5.6023 21.4815 5.37119 21.1212 5.44532L19.8159 5.71674C19.4556 5.79234 19.2241 6.14513 19.2989 6.50584Z"
            fill="#A6A6A6"
          />
          <path
            d="M19.2864 12.4196C19.1864 12.1664 18.9396 11.9997 18.6665 11.9997H5.33341C5.06005 11.9997 4.8135 12.1664 4.7135 12.4196L3.38019 15.7529C3.30013 15.9596 3.32692 16.1929 3.44686 16.3728C3.57349 16.5528 3.78019 16.6661 4.0001 16.6661H4.66676V21.3326C4.66676 21.6993 4.96675 21.9993 5.33341 21.9993H18.6665C19.0331 21.9993 19.3331 21.6993 19.3331 21.3326V16.6661H19.9998C20.2197 16.6661 20.4264 16.5527 20.553 16.3728C20.6729 16.1927 20.6997 15.9594 20.6197 15.7529L19.2864 12.4196ZM17.9998 20.6663H6.00006V16.6664H17.9998V20.6663Z"
            fill="#A6A6A6"
          />
          <path
            d="M7.71404 11.2433C8.03308 11.059 8.14173 10.6515 7.95749 10.3324C7.54931 9.62665 7.33354 8.82012 7.33354 7.99975C7.33354 5.4269 9.42694 3.33319 12.0001 3.33319C14.5733 3.33319 16.6667 5.4269 16.6667 7.99975C16.6667 8.82012 16.4509 9.62665 16.0427 10.3324C15.8585 10.6515 15.9671 11.0589 16.2862 11.2433C16.3912 11.3044 16.506 11.3331 16.6192 11.3331C16.8493 11.3331 17.0734 11.214 17.197 11.0004C17.7224 10.0915 18.0001 9.05447 18.0001 7.99987C18.0001 4.69129 15.3085 2 12.0002 2C8.69183 2 6.00038 4.69129 6.00038 7.99987C6.00038 9.05447 6.27805 10.0916 6.80349 11.0004C6.98816 11.3187 7.39646 11.4288 7.71432 11.2433H7.71404Z"
            fill="#A6A6A6"
          />
          <path
            d="M13.8936 7.07042H13.0048C12.9886 6.95544 12.9554 6.8533 12.9054 6.76402C12.8553 6.67338 12.7911 6.59627 12.7126 6.53269C12.6341 6.46911 12.5435 6.42041 12.4407 6.38659C12.3392 6.35277 12.229 6.33586 12.1099 6.33586C11.8949 6.33586 11.7075 6.3893 11.5479 6.49617C11.3882 6.60168 11.2645 6.7559 11.1765 6.95882C11.0886 7.16038 11.0446 7.40524 11.0446 7.69338C11.0446 7.98964 11.0886 8.23855 11.1765 8.44012C11.2658 8.64168 11.3903 8.79387 11.5499 8.89668C11.7095 8.99949 11.8942 9.0509 12.1039 9.0509C12.2215 9.0509 12.3304 9.03534 12.4306 9.00423C12.532 8.97311 12.622 8.92779 12.7004 8.86827C12.7789 8.8074 12.8438 8.73367 12.8952 8.64709C12.948 8.56051 12.9845 8.46176 13.0048 8.35083L13.8936 8.35489C13.8706 8.54563 13.8131 8.72961 13.7211 8.90683C13.6305 9.08269 13.508 9.24029 13.3538 9.37963C13.201 9.51761 13.0183 9.62718 12.806 9.70835C12.5949 9.78817 12.3562 9.82807 12.0897 9.82807C11.719 9.82807 11.3876 9.7442 11.0954 9.57646C10.8045 9.40871 10.5745 9.16589 10.4054 8.84798C10.2377 8.53008 10.1538 8.14521 10.1538 7.69338C10.1538 7.2402 10.239 6.85465 10.4095 6.53675C10.5799 6.21885 10.8113 5.9767 11.1035 5.8103C11.3957 5.64256 11.7244 5.55869 12.0897 5.55869C12.3304 5.55869 12.5537 5.59251 12.7593 5.66015C12.9663 5.72778 13.1496 5.82654 13.3092 5.9564C13.4688 6.08492 13.5987 6.24252 13.6988 6.4292C13.8002 6.61589 13.8652 6.82963 13.8936 7.07042Z"
            fill="#A6A6A6"
          />
        </svg>
        <Typography fontSize="body" fontWeight="medium" color="neutral-900">
          {credits}
        </Typography>
      </div>
      <AnchorLink to={`${links.pricing}#consumption-overview`}>
        <Typography className="TDB-Plan__credits-subtext" fontWeight="medium" color="neutral-600">
          after included credits, <span className="TDB-Plan__underline">pay as you go</span>
        </Typography>
      </AnchorLink>

      {seats && (
        <>
          <div className="TDB-Plan__infobox TDB-Plan__infobox--top-margin">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20.0714 14H18.2857C18.3571 14.3571 18.4286 14.7143 18.4286 15.0714V18.4286C18.4286 18.7143 18.3571 18.9286 18.2857 19.1429H21.2857C21.6429 19.1429 22 18.7857 22 18.4286V15.9286C22 14.9286 21.1429 14 20.0714 14Z"
                fill="#A6A6A6"
              />
              <path
                d="M20.5 10.5C20.5 9.07143 19.3571 7.92857 18 7.92857C16.6429 7.92857 15.5 9.07143 15.5 10.5C15.5 11.9286 16.6429 13.0714 18 13.0714C19.3571 13.0714 20.5 11.8571 20.5 10.5Z"
                fill="#A6A6A6"
              />
              <path
                d="M5.71429 14H3.92857C2.85714 14 2 14.8571 2 15.9286V18.4286C2 18.7857 2.35714 19.1429 2.71429 19.1429H5.64286C5.57143 19 5.57143 18.7143 5.57143 18.5V15.1429C5.5 14.7143 5.57143 14.3571 5.71429 14Z"
                fill="#A6A6A6"
              />
              <path
                d="M8.42857 10.5C8.42857 9.07143 7.28571 7.92857 5.92857 7.92857C4.57143 7.92857 3.42857 9.07143 3.42857 10.5C3.42857 11.9286 4.57143 13.0714 5.92857 13.0714C7.28571 13.0714 8.42857 11.8571 8.42857 10.5Z"
                fill="#A6A6A6"
              />
              <path
                d="M14.7143 12.7857H9.35714C8.07143 12.7857 7 13.8571 7 15.1429V18.5C7 18.9286 7.28571 19.2143 7.71429 19.2143H16.2857C16.7143 19.2143 17 18.9286 17 18.5V15.1429C17 13.8571 16 12.7857 14.7143 12.7857Z"
                fill="#A6A6A6"
              />
              <path
                d="M11.9286 11.4286C13.6429 11.4286 15.0714 10 15.0714 8.21429C15.0714 6.42857 13.6429 5 11.9286 5C10.2143 5 8.78571 6.42857 8.78571 8.21429C8.78571 10 10.1429 11.4286 11.9286 11.4286Z"
                fill="#A6A6A6"
              />
            </svg>

            <Typography fontSize="body" fontWeight="medium" color="neutral-900">
              {seats}
            </Typography>
          </div>

          <Typography as="p" className="TDB-Plan__seat-subtitle" fontWeight="medium" color="neutral-600">
            {seatSubtitle || '\u00A0'}
          </Typography>
        </>
      )}

      <div className="TDB-Plan__main">
        {features?.map((f) => (
          <div
            key={f.key}
            className={classNames('TDB-Plan__feature', {
              'TDB-Plan__feature--active': f.active,
            })}
          >
            {f.active ? (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path
                    d="M12.0182 22C13.3832 22 14.6676 21.7386 15.8714 21.2157C17.0752 20.6928 18.1377 19.9723 19.0588 19.0543C19.9769 18.1362 20.6974 17.0722 21.2202 15.8623C21.7401 14.6524 22 13.3649 22 12C22 10.632 21.7386 9.3461 21.2157 8.14227C20.6928 6.9354 19.9708 5.87141 19.0497 4.9503C18.1286 4.02918 17.0631 3.30719 15.8532 2.78431C14.6433 2.26144 13.3558 2 11.9909 2C10.6229 2 9.33698 2.25992 8.13315 2.77975C6.92628 3.29959 5.86381 4.02007 4.94574 4.94118C4.02766 5.85925 3.30719 6.92172 2.78431 8.12859C2.26144 9.33242 2 10.6168 2 11.9818C2 13.3467 2.25992 14.6326 2.77975 15.8395C3.29959 17.0464 4.02006 18.1088 4.94118 19.0269C5.85925 19.9419 6.92172 20.6609 8.12859 21.1838C9.33242 21.7036 10.6168 21.9635 11.9818 21.9635L12.0182 22ZM10.673 17.0296C10.5028 17.0296 10.3493 16.9916 10.2125 16.9156C10.0727 16.8396 9.93434 16.7165 9.79754 16.5463L6.9658 13.0579C6.89284 12.9637 6.83508 12.8618 6.79252 12.7524C6.74996 12.6399 6.72868 12.5274 6.72868 12.415C6.72868 12.1839 6.8062 11.9909 6.96124 11.8358C7.11628 11.6778 7.30476 11.5987 7.52668 11.5987C7.66347 11.5987 7.79267 11.6291 7.91427 11.6899C8.03587 11.7507 8.15291 11.8556 8.26539 12.0046L10.6047 15.0278L15.1601 7.72731C15.3485 7.42636 15.5826 7.27588 15.8623 7.27588C16.0751 7.27588 16.2681 7.34276 16.4414 7.47652C16.6116 7.61028 16.6968 7.79419 16.6968 8.02827C16.6968 8.13771 16.6694 8.24867 16.6147 8.36115C16.56 8.47059 16.5037 8.57395 16.446 8.67123L11.4574 16.5144C11.351 16.6755 11.2278 16.7956 11.088 16.8746C10.9451 16.9506 10.7931 16.9886 10.632 16.9886L10.673 17.0296Z"
                    fill="#0077FF"
                  />
                </g>
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 10.8215L8.42108 7.24257C8.28108 7.10257 8.17131 7.03656 8.05341 7.00082C7.9355 6.96509 7.81492 6.96509 7.69701 7.00082C7.57911 7.03656 7.46934 7.10257 7.32934 7.24257L7.24257 7.32934C7.10257 7.46934 7.03656 7.57911 7.00082 7.69701C6.96509 7.81492 6.96509 7.9355 7.00082 8.05341C7.03656 8.17131 7.10257 8.28108 7.24257 8.42108L10.8215 12L7.24257 15.5789C7.10257 15.7189 7.03656 15.8287 7.00082 15.9466C6.96509 16.0645 6.96509 16.1851 7.00082 16.303C7.03656 16.4209 7.10257 16.5307 7.24257 16.6707L7.32934 16.7574C7.46934 16.8974 7.57911 16.9634 7.69701 16.9992C7.81492 17.0349 7.9355 17.0349 8.05341 16.9992C8.17131 16.9634 8.28108 16.8974 8.42108 16.7574L12 13.1785L15.5789 16.7574C15.7189 16.8974 15.8287 16.9634 15.9466 16.9992C16.0645 17.0349 16.1851 17.0349 16.303 16.9992C16.4209 16.9634 16.5307 16.8974 16.6707 16.7574L16.7574 16.6707C16.8974 16.5307 16.9634 16.4209 16.9992 16.303C17.0349 16.1851 17.0349 16.0645 16.9992 15.9466C16.9634 15.8287 16.8974 15.7189 16.7574 15.5789L13.1785 12L16.7574 8.42108C16.8974 8.28108 16.9634 8.17131 16.9992 8.05341C17.0349 7.9355 17.0349 7.81492 16.9992 7.69701C16.9634 7.57911 16.8974 7.46934 16.7574 7.32934L16.6707 7.24257C16.5307 7.10257 16.4209 7.03656 16.303 7.00082C16.1851 6.96509 16.0645 6.96509 15.9466 7.00082C15.8287 7.03656 15.7189 7.10257 15.5789 7.24257L12 10.8215Z"
                    fill="#828282"
                  />
                </g>
              </svg>
            )}
            {f.text}
            {f.tooltip && (
              <Tooltip content={f.tooltip}>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7 0.333313C10.682 0.333313 13.6667 3.31798 13.6667 6.99998C13.6667 10.682 10.682 13.6666 7 13.6666C3.318 13.6666 0.333328 10.682 0.333328 6.99998C0.333328 3.31798 3.318 0.333313 7 0.333313ZM7 6.33331H5V7.33331H5.33333C5.7 7.33331 6 7.62665 6 7.99998V9.99998C6 10.5466 6.44666 11 7 11H9V9.99998H8.66666C8.29333 9.99998 8 9.69998 8 9.33331V7.33331C8 6.77998 7.54666 6.33331 7 6.33331ZM7 3.16665C6.35333 3.16665 5.83333 3.68665 5.83333 4.33331C5.83333 4.97331 6.35333 5.49998 7 5.49998C7.64 5.49998 8.16666 4.97331 8.16666 4.33331C8.16666 3.68665 7.64 3.16665 7 3.16665Z"
                    fill="#A6A6A6"
                  />
                </svg>
              </Tooltip>
            )}
          </div>
        ))}
      </div>

      <div className="TDB-Plan__footer">
        {renderSignupButton ? (
          <Button block primary to={links.console.signup}>
            Sign up
          </Button>
        ) : (
          <Button block primary to={links.contactSales}>
            Contact Sales
          </Button>
        )}
        <div className="TDB-Plan__infobox TDB-Plan__infobox--middle">
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.8143 1.66669C12.4479 1.66669 11.2384 2.33987 10.5 3.37263C9.76148 2.33987 8.55207 1.66669 7.1857 1.66669H7.09096C5.6266 1.66669 4.43939 2.8539 4.43939 4.31825C4.43939 4.72484 4.53096 5.11017 4.69457 5.45462H3.68187C2.84508 5.45462 2.16667 6.13303 2.16667 6.96982V7.72735C2.16667 8.56414 2.84508 9.24255 3.68187 9.24255H8.98481C9.40322 9.24255 9.74247 8.9033 9.74247 8.48489V5.45462H11.2575V8.48489C11.2575 8.9033 11.5968 9.24255 12.0152 9.24255H17.3181C18.1549 9.24255 18.8333 8.56414 18.8333 7.72735V6.96982C18.8333 6.13303 18.1549 5.45462 17.3181 5.45462H16.3054C16.4691 5.11017 16.5606 4.72484 16.5606 4.31825C16.5606 2.8539 15.3734 1.66669 13.9091 1.66669H13.8143ZM13.909 5.45468H11.273C11.4143 4.17627 12.4982 3.18195 13.8143 3.18195H13.909C14.5366 3.18195 15.0454 3.69078 15.0454 4.31832C15.0454 4.94585 14.5366 5.45468 13.909 5.45468ZM7.09083 3.18195C6.46329 3.18195 5.95446 3.69078 5.95446 4.31832C5.95446 4.94585 6.46329 5.45468 7.09083 5.45468H9.72681C9.58552 4.17627 8.50168 3.18195 7.18557 3.18195H7.09083ZM3.68173 11.5154C3.68173 11.097 4.02085 10.7578 4.43926 10.7578H8.98473C9.40314 10.7578 9.74239 11.097 9.74239 11.5154V17.5758C9.74239 17.9942 9.40314 18.3335 8.98473 18.3335H5.19706C4.36027 18.3335 3.68186 17.6551 3.68186 16.8183L3.68173 11.5154ZM11.2574 11.5154C11.2574 11.097 11.5967 10.7578 12.0151 10.7578H16.5605C16.9789 10.7578 17.3181 11.097 17.3181 11.5154V16.8184C17.3181 17.6551 16.6397 18.3336 15.8029 18.3336H12.0152C11.5968 18.3336 11.2575 17.9943 11.2575 17.5759L11.2574 11.5154Z"
              fill="#A6A6A6"
            />
          </svg>
          <Typography fontSize="body-sm" fontWeight="medium" color="neutral-900">
            {gift}
          </Typography>
        </div>
      </div>
    </motion.div>
  );
};

export default Plan;
