import * as React from 'react';
import Typography from '@components/Typography';
import Plan from '../Plan';
import './OrganizationsPricingPlan.scss';

const OrganizationsPricingPlan: React.FC = () => {
  return (
    <div className="TDB-OrganizationsPricingPlan">
      <Typography
        className="TDB-OrganizationsPricingPlan__Description"
        fontSize="subheading-3"
        fontWeight="medium"
        color="neutral-600"
        align="center"
        as="p"
      >
        For universities, startups, and enterprises. Manage complex data and conduct advanced compute at any scale while organizing your
        users into teams.
      </Typography>
      <div className="TDB-OrganizationsPricingPlan__main">
        <Plan
          title="University Program"
          description="Any academic organization"
          pricing="$10K / year"
          credits="Included credits - 10K$ / year"
          gift="1 year free with $10K credits"
          seats="Seats - 10"
          seatSubtitle="then $100 / seat"
          features={[
            {
              active: true,
              text: 'All TileDB Cloud Features',
              key: 'University-1',
            },
            {
              active: true,
              text: 'Organizations',
              key: 'University-2',
              tooltip:
                'An organization is a sharing and permissions function in the TileDB Cloud. An organization allows you to manage access controls for groups of users, mapping permissions to the way your organization already works. This group of users can be individuals from your own company or even collaborators outside your own company, that you can invite to any TileDB asset such as notebooks, models, arrays, dashboards, task graphs and serverless code.',
            },
            {
              active: true,
              text: 'Basic Support',
              key: 'University-3',
            },
            {
              active: false,
              key: 'University-4',
              text: 'On-prem option',
            },
          ]}
          renderSignupButton
        />
        <Plan
          title="Startup Program"
          description="Small companies pending qualification"
          pricing="$25K / year"
          pricingPrefix="Starts at "
          credits="Included credits - 10K$ / year"
          gift="1 month free with $250 credits"
          seats="Flexibility on seats"
          features={[
            {
              active: true,
              key: 'Startup-1',
              text: 'All TileDB Cloud Features',
            },
            {
              active: true,
              text: 'Organizations',
              key: 'Startup-2',
              tooltip:
                'An organization is a sharing and permissions function in the TileDB Cloud. An organization allows you to manage access controls for groups of users, mapping permissions to the way your organization already works. This group of users can be individuals from your own company or even collaborators outside your own company, that you can invite to any TileDB asset such as notebooks, models, arrays, dashboards, task graphs and serverless code.',
            },
            {
              active: true,
              key: 'Startup-3',
              text: 'Basic Support',
            },
            {
              active: false,
              key: 'Startup-4',
              text: 'On-prem option',
            },
          ]}
          renderSignupButton={false}
        />
        <Plan
          title="Enterprise"
          description="Large companies with critical workloads"
          pricing="Custom pricing"
          credits="Custom included credits"
          gift="Custom trial period & free credits"
          seats="Flexibility on seats"
          features={[
            {
              active: true,
              key: 'Enterprise-1',
              text: 'All TileDB Cloud Features',
            },
            {
              active: true,
              text: 'Organizations',
              key: 'Enterprise-2',
              tooltip:
                'An organization is a sharing and permissions function in the TileDB Cloud. An organization allows you to manage access controls for groups of users, mapping permissions to the way your organization already works. This group of users can be individuals from your own company or even collaborators outside your own company, that you can invite to any TileDB asset such as notebooks, models, arrays, dashboards, task graphs and serverless code.',
            },
            {
              active: true,
              text: 'Advanced Support',
              key: 'Enterprise-3',
              tooltip: (
                <>
                  <Typography fontSize="body-sm" fontWeight="semi-bold" color="neutral-900" as="p">
                    Advanced support includes the following
                  </Typography>
                  <ul>
                    <li>Priority escalation of feature requests</li>
                    <li>Assistance with performance optimizations</li>
                    <li>Domain-specific support to accelerate adoption of TileDB Embedded or TileDB Cloud Training sessions</li>
                    <li>Architectural guidance and deployment assistance</li>
                  </ul>
                </>
              ),
            },
            {
              active: true,
              key: 'Enterprise-4',
              text: 'On-prem option',
            },
          ]}
          renderSignupButton={false}
        />
      </div>
    </div>
  );
};

export default OrganizationsPricingPlan;
