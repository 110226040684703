import * as React from 'react';
import Typography from '@components/Typography';
import Plan from '../Plan';
import './IndividualsPricingPlan.scss';

const IndividualsPricingPlan: React.FC = () => {
  return (
    <div className="TDB-IndividualsPricingPlan">
      <Typography
        className="TDB-IndividualsPricingPlan__Description"
        fontSize="subheading-3"
        fontWeight="medium"
        color="neutral-600"
        align="center"
        as="p"
      >
        For citizen scientists, independent research professionals and students. Manage your own data, access public datasets & code,
        conduct analysis and connect with the TileDB Cloud Community
      </Typography>
      <div className="TDB-IndividualsPricingPlan__main">
        <Plan
          title="Basic"
          description="A citizen scientist"
          pricing="$10 / month"
          credits="Included credits - 10$ / month"
          gift="1 month free with $10 credits"
          features={[
            {
              active: true,
              text: 'All TileDB Cloud Features',
              key: 'basic-1',
            },
            {
              active: false,
              text: 'Organizations',
              key: 'basic-2',
              tooltip:
                'An organization is a sharing and permissions function in the TileDB Cloud. An organization allows you to manage access controls for groups of users, mapping permissions to the way your organization already works. This group of users can be individuals from your own company or even collaborators outside your own company, that you can invite to any TileDB asset such as notebooks, models, arrays, dashboards, task graphs and serverless code.',
            },
            {
              active: false,
              key: 'basic-3',
              text: 'Enterprise Support',
            },
          ]}
          renderSignupButton
        />
        <Plan
          title="Student"
          description="Anyone with .edu email"
          pricing="$0 / month"
          credits="Included credits - 10$ / month"
          gift="Every month free"
          features={[
            {
              active: true,
              text: 'All TileDB Cloud Features',
              key: 'Student-1',
            },
            {
              active: false,
              text: 'Organizations',
              key: 'Student-2',
              tooltip:
                'An organization is a sharing and permissions function in the TileDB Cloud. An organization allows you to manage access controls for groups of users, mapping permissions to the way your organization already works. This group of users can be individuals from your own company or even collaborators outside your own company, that you can invite to any TileDB asset such as notebooks, models, arrays, dashboards, task graphs and serverless code.',
            },
            {
              active: false,
              key: 'Student-3',
              text: 'Enterprise Support',
            },
          ]}
          renderSignupButton
        />
      </div>
    </div>
  );
};

export default IndividualsPricingPlan;
